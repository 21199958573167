import React from "react"
import Grid from "@material-ui/core/Grid"
import { Link } from 'gatsby';
import { graphql } from "gatsby"
import serviceStyles from "./service.module.scss"
import SEO from "../../components/seo"
import desarrollo from "../../images/desarrollo.jpg"

export default function Dev({ data }) {
  return (
    <>
      <SEO
        title="Development"
        description="Tenemos un equipo experimentado en la creación de software y diseño web centrado en el usuario."
        image={`https://www.ppdc.mx${desarrollo}`}
        url="/services/dev"
      />
      <Grid container className={serviceStyles.serviceHero}>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          className={serviceStyles.serviceHeroTitleContainer}
        >
          <h1 className={serviceStyles.serviceHeroTitle}>Development</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          className={serviceStyles.serviceHeroImageDescriptionContainerDev}
        >
          <div className={serviceStyles.serviceHeroDescriptionContainer}>
            <h1 className={serviceStyles.serviceHeroTitleTwo}>Development</h1>
            <p className={serviceStyles.serviceHeroDescription}>
              Tenemos un equipo experimentado en la creación de software y
              diseño web centrado en el usuario. Utilizamos las metodologías más
              vanguardistas para la innovación como{" "}
              <strong>Design Sprint</strong>. Creamos desde un sitio en
              Wordpress hasta el desarrollo de aplicaciones robustas con{" "}
              <strong>React</strong>,<strong> Vue</strong> y{" "}
              <strong>Django</strong> o <strong>Node</strong>.
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container className={serviceStyles.gridContainer}>
        {data.allAirtable.edges.map(edge => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={serviceStyles.serviceContainer}
              id={edge.node.id}
            >
              <img
                src={
                  edge.node.data.Image
                    ? edge.node.data.Image
                    : edge.node.data.ImageFile.map(image => {
                        return image.url
                      })
                }
                alt={`Imagen de ${edge.node.data.Title}`}
                className={serviceStyles.serviceImage}
              />
              <div className={serviceStyles.serviceTextArea}>
                <h2 className={serviceStyles.serviceTitle}>
                  {edge.node.data.Title}
                </h2>
                {/* <p className={serviceStyles.serviceDescription}>{edge.node.data.Description}</p> */}
                <Link title="contact" paintDrip hex="#DAD4CC" to="/contact">
                  <button className={serviceStyles.ctaButton}>Saber Más</button>
                </Link>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export const data = graphql`
  query airtableDev {
    allAirtable(
      sort: { fields: data___Order }
      filter: { data: { Category: { eq: "Dev" } } }
    ) {
      edges {
        node {
          id
          data {
            Description
            Category
            Title
            Image
            ImageFile {
              url
            }
          }
        }
      }
    }
  }
`
